// src/components/BoostModal.js
import React from 'react';

const BoostModal = ({ closeModal }) => {
  return (
    <div style={{
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <div style={{
        backgroundColor: 'white',
        padding: '2rem',
        borderRadius: '8px',
        width: '400px',
        textAlign: 'center'
      }}>
        <h2>Boost Options</h2>
        <p>Choose from various boosting options:</p>
        {/* Additional Boost Options can be listed here */}
        <button onClick={closeModal} style={{ marginTop: '1rem', padding: '0.5rem 1rem' }}>
          Close
        </button>
      </div>
    </div>
  );
};

export default BoostModal;
