// src/components/MiningDashboard.js
import React, { useState, useEffect } from 'react';
import BoostModal from './BoostModal';

const MiningDashboard = () => {
  const [balance, setBalance] = useState(0);
  const [showBoostModal, setShowBoostModal] = useState(false);

  useEffect(() => {

    fetch(`${process.env.REACT_APP_API_BASE_URL}/balance`)
      .then(response => response.json())
      .then(data => setBalance(data.balance))
      .catch(error => console.error('Error fetching balance:', error));
  }, []);

  const toggleBoostModal = () => setShowBoostModal(!showBoostModal);

  return (
    <div style={{ padding: '2rem' }}>
      <h1>Polo Mining Manager</h1>
      
      <div style={{ marginBottom: '1.5rem', fontSize: '1.2rem', textAlign: 'center' }}>
        <p>Balance: <strong>{balance}</strong> PoloTokens</p>
      </div>

      {/* Rotating Engine GIF */}
      <div style={{ display: 'flex', justifyContent: 'center', margin: '2rem 0' }}>
        <img src="/path/to/rotating-engine.gif" alt="Mining Engine" style={{ width: '150px', height: '150px' }} />
      </div>

      {/* Display mining package details */}


      {/* Boost Button */}
      <div style={{ textAlign: 'center', marginTop: '2rem' }}>
        <button onClick={toggleBoostModal} style={{ padding: '1rem 2rem', fontSize: '1rem' }}>
          Boost
        </button>
      </div>

      {/* Boost Modal */}
      {showBoostModal && <BoostModal closeModal={toggleBoostModal} />}
    </div>
  );
};

export default MiningDashboard;
