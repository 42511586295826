// src/App.js

import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import MiningDashboard from "./components/MiningDashboard";

const App = () => {
  useEffect(() => {
    // Check if the app is running in Telegram's WebApp environment
    if (window.Telegram && window.Telegram.WebApp) {
      const tg = window.Telegram.WebApp;

      // Initialize the Telegram WebApp
      tg.ready();
      console.log("Telegram Web App initialized");

      // Optional: Log the data Telegram passes
      console.log("Telegram WebApp Data:", tg.initDataUnsafe);
    } else {
      console.error("Telegram WebApp environment not detected.");
    }
  }, []);

  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<MiningDashboard />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
