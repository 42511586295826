// src/components/Footer.js
import React from 'react';

function Footer() {
  return (
    <footer style={{ padding: '1rem', backgroundColor: '#282c34', color: 'white', textAlign: 'center' }}>
      <p>&copy; 2023 Polo Mining</p>
    </footer>
  );
}

export default Footer;
